import { ofType, StateObservable } from "redux-observable";
import { filter, map } from "rxjs/operators";
import { wrapAsync, unwrapAsync, checkAsync, getPunksDataImageAsync, approveV1Async, approveV2Async } from "./perfectPunksSlice";
import { RootState } from "../../app/store";
import { CheckPunkRequest, CheckPunksResponse } from "./perfectPunksApi";

export const recheckPunkEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(wrapAsync.fulfilled, unwrapAsync.fulfilled, approveV1Async.fulfilled, approveV2Async.fulfilled),
  map(() => state$.value.perfectPunks.check.request),
  filter(request => !!request),
  map((request: CheckPunkRequest) => checkAsync(request)),
);

export const loadImageAfterCheckEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(checkAsync.fulfilled),
  filter(({ payload }: { payload: CheckPunksResponse }) => {
    return (payload.perfectPunkIsOwnedByUser ||
      (
        (payload.v1IsOwnedByUser || payload.v1WrappedIsOwnedByUser) &&
        (payload.v2IsOwnedByUser || payload.v2WrappedIsOwnedByUser)
      )
    );
  }),
  map(() => state$.value.perfectPunks.check.request),
  filter(request => !!request),
  map((request: CheckPunkRequest) => getPunksDataImageAsync({ punkId: request.punkId })),
);
