import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectWrapLoading,
  selectUnwrapLoading,
  selectCheckLoading,
  selectCheckError,
  selectCheckResponse,
  checkAsync,
  selectCheckRequest,
  selectPunksDataImage
} from './perfectPunksSlice';
import styles from './PerfectPunk.module.css';
import { ButtonWithInput, LoadingOrErrorOrContent, selectAddress } from '@cyberpnk/component-library';
import { UnwrappedPunks } from './UnwrappedPunks';
import { PerfectPunk } from './PerfectPunk';

export function PerfectPunks() {
  const dispatch = useAppDispatch();

  const wrapLoading = useAppSelector(selectWrapLoading);
  const unwrapLoading = useAppSelector(selectUnwrapLoading);
  const checkLoading = useAppSelector(selectCheckLoading);
  const checkError = useAppSelector(selectCheckError);
  const checkResponse = useAppSelector(selectCheckResponse);
  const checkRequest = useAppSelector(selectCheckRequest);
  const expectedOwner = useAppSelector(selectAddress);
  const punksDataImage = useAppSelector(selectPunksDataImage);

  const showPerfectPunk = checkResponse?.perfectPunkIsOwnedByUser;
  const showUnwrappedPunks = !checkResponse?.perfectPunkIsOwnedByUser && 
    (checkResponse?.v1WrappedIsOwnedByUser || checkResponse?.v1IsOwnedByUser) &&
    (checkResponse?.v2WrappedIsOwnedByUser || checkResponse?.v2IsOwnedByUser);
  const showV1PunkNotYours = !checkResponse?.perfectPunkIsOwnedByUser &&
    !checkResponse?.v1IsOwnedByUser &&
    !checkResponse?.v1WrappedIsOwnedByUser;
  const showV2PunkNotYours = !checkResponse?.perfectPunkIsOwnedByUser &&
    !checkResponse?.v2IsOwnedByUser &&
    !checkResponse?.v2WrappedIsOwnedByUser;

  const [inputValuetokenId, setInputValueTokenId] = useState("");
  const noValuetokenId = inputValuetokenId === undefined || inputValuetokenId === "";
  const lessThanMinTokenId = !noValuetokenId && inputValuetokenId !== undefined && +inputValuetokenId < 0;
  const moreThanMaxtokenId = !noValuetokenId && inputValuetokenId !== undefined && +inputValuetokenId > 9999;
  const noNumberInputTokenId = isNaN(+inputValuetokenId);
  const invalidInputTokenId = noNumberInputTokenId || lessThanMinTokenId || moreThanMaxtokenId;
  const disabledInput = wrapLoading || unwrapLoading || checkLoading;
  const disabledButton = invalidInputTokenId || wrapLoading || unwrapLoading || checkLoading;

  const errorTextChecking = noNumberInputTokenId ? "Enter a value" :
    lessThanMinTokenId || moreThanMaxtokenId ? `Enter the punk ID between 0 and 9999` :
      checkError ? checkError : "";

  return (
    <div className={styles.claim}>
      <p>Enter you punk's ID <ButtonWithInput cta={true} disabled={disabledButton} onClick={() => {
        dispatch(checkAsync({ punkId: inputValuetokenId, expectedOwner }));
      }} input={{
        cta: true,
        onChange: (value) => {
          setInputValueTokenId(value || "");
        },
        disabled: disabledInput,
        error: invalidInputTokenId,
        placeholder: "e.g. 2121",
        value: `${inputValuetokenId}`
      }}>Load Punk</ButtonWithInput></p>
      <LoadingOrErrorOrContent loading={checkLoading} error={errorTextChecking} loadingText={"Checking punk..."}>
        {checkResponse && checkRequest ? <>
          {showV1PunkNotYours ? <p>V1 punk #{checkRequest.punkId} doesn't seem to belong to the connected wallet</p> : null}
          {showV2PunkNotYours ? <p>V2 punk #{checkRequest.punkId} doesn't seem to belong to the connected wallet</p> : null}
          {showPerfectPunk ? <PerfectPunk punkId={checkRequest.punkId} punksDataImage={punksDataImage} /> : null}
          {showUnwrappedPunks ? <UnwrappedPunks punkId={checkRequest.punkId} punksDataImage={punksDataImage} /> : null}
        </>
          : null}
      </LoadingOrErrorOrContent>
    </div>
  );
}
