import { callAndWaitForTransfer, callAndWaitForEvent } from "@cyberpnk/component-library";
import {
  getPerfectPunksContract, getV1Contract,
  getV1WrappedContract, getV2Contract, getV2WrappedContract,
  getCryptoPunksDataContract,
  getContractAddress
} from "../../app/common";

declare global {
  interface Window { ethereum: any; }
}

export const wrap = async (punkId: string) => {
  return callAndWaitForTransfer(getPerfectPunksContract, "wrap", [punkId]);
}

export const unwrap = async (punkId: string) => {
  return callAndWaitForTransfer(getPerfectPunksContract, "unwrap", [punkId], getV1WrappedContract);
}

const doesntExist = (error: Error) => {
  if (/nonexistent token/.test(error.message)) {
    return "";
  }
  throw error;
};

export interface CheckPunkRequest {
  punkId: string,
  expectedOwner: string,
}

export interface CheckPunksResponse {
  perfectPunkIsOwnedByUser: boolean;
  v1IsOwnedByUser: boolean;
  v2IsOwnedByUser: boolean;
  v1WrappedIsOwnedByUser: boolean;
  v2WrappedIsOwnedByUser: boolean;
  v1WrappedIsApproved: boolean;
  v2WrappedIsApproved: boolean;
}

export const check = async ({ punkId, expectedOwner }: CheckPunkRequest): Promise<CheckPunksResponse> => {
  const perfectPunks = await getPerfectPunksContract();
  const perfectPunksAddress = perfectPunks.options.address;
  const ownerOfPerfectPunk = await perfectPunks.methods.ownerOf(punkId).call().catch(doesntExist);
  const perfectPunkIsOwnedByUser = ownerOfPerfectPunk === expectedOwner;
  if (perfectPunkIsOwnedByUser) {
    return {
      perfectPunkIsOwnedByUser: true,
      v1IsOwnedByUser: false,
      v2IsOwnedByUser: false,
      v1WrappedIsOwnedByUser: false,
      v2WrappedIsOwnedByUser: false,
      v1WrappedIsApproved: false,
      v2WrappedIsApproved: false,
    };
  }
  const v1Wrapped = await getV1WrappedContract();
  const v2Wrapped = await getV2WrappedContract();
  const v1 = await getV1Contract();
  const v2 = await getV2Contract();
  const ownerOfV1Wrapped = await v1Wrapped.methods.ownerOf(punkId).call().catch(doesntExist);
  const v1WrappedIsOwnedByUser = ownerOfV1Wrapped === expectedOwner;
  const ownerOfV1 = v1WrappedIsOwnedByUser ? v1Wrapped._address : await v1.methods.punkIndexToAddress(punkId).call();
  const v1IsOwnedByUser = ownerOfV1 === expectedOwner;
  const v1WrappedApprovedAddress = v1WrappedIsOwnedByUser ? await v1Wrapped.methods.getApproved(punkId).call().catch(doesntExist) : "";
  const v1WrappedIsApprovedForAll = v1WrappedIsOwnedByUser ? await v1Wrapped.methods.isApprovedForAll(expectedOwner, perfectPunksAddress).call().catch(doesntExist) : false;
  const v1WrappedIsApproved = v1WrappedApprovedAddress === perfectPunksAddress || v1WrappedIsApprovedForAll;

  const ownerOfV2Wrapped = await v2Wrapped.methods.ownerOf(punkId).call().catch(doesntExist);
  const v2WrappedIsOwnedByUser = ownerOfV2Wrapped === expectedOwner;
  const ownerOfV2 = v2WrappedIsOwnedByUser ? v2Wrapped._address : await v2.methods.punkIndexToAddress(punkId).call();
  const v2IsOwnedByUser = ownerOfV2 === expectedOwner;
  const v2WrappedApprovedAddress = v2WrappedIsOwnedByUser ? await v2Wrapped.methods.getApproved(punkId).call().catch(doesntExist) : "";
  const v2WrappedIsApprovedForAll = v2WrappedIsOwnedByUser ? await v2Wrapped.methods.isApprovedForAll(expectedOwner, perfectPunksAddress).call().catch(doesntExist) : false;
  const v2WrappedIsApproved = v2WrappedApprovedAddress === perfectPunksAddress || v2WrappedIsApprovedForAll;

  return {
    perfectPunkIsOwnedByUser,
    v1IsOwnedByUser,
    v2IsOwnedByUser,
    v1WrappedIsOwnedByUser,
    v2WrappedIsOwnedByUser,
    v1WrappedIsApproved,
    v2WrappedIsApproved,
  };
}

export const getPunksDataImage = async ({ punkId }: { punkId: string }): Promise<string> => {
  const cryptoPunksData = await getCryptoPunksDataContract();
  return cryptoPunksData.methods.punkImageSvg(punkId).call();
}

export const getPunksDataImageWithLocalStorageCache = async ({ punkId }: { punkId: string }): Promise<string> => {
  let myStorage;
  try {
    myStorage = window.localStorage;
    const key = `PunksData_punkImageSvg_${punkId}`;
    const imageInStorage = myStorage.getItem(key);
    if (imageInStorage) {
      return imageInStorage;
    }
    const image = await getPunksDataImage({ punkId });
    myStorage.setItem(key, image);
    return image;
  } catch (e) {
    return getPunksDataImage({ punkId });
  }
}

export const approveV1 = async (punkId: string) => {
  const perfectPunksAddress = await getContractAddress("PerfectPunks");
  return callAndWaitForEvent(getV1WrappedContract, "approve(address,uint256)", [perfectPunksAddress, punkId], "Approval", { approved: perfectPunksAddress, tokenId: punkId });
}

export const approveV2 = async (punkId: string) => {
  const perfectPunksAddress = await getContractAddress("PerfectPunks");
  return callAndWaitForEvent(getV2WrappedContract, "approve(address,uint256)", [perfectPunksAddress, punkId], "Approval", { approved: perfectPunksAddress, tokenId: punkId });
}