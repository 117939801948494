import React from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  unwrapAsync,
  selectUnwrapError,
  selectUnwrapLoading
} from './perfectPunksSlice';
import styles from './PerfectPunk.module.css';
import { Button, LoadingOrErrorOrContent, LoadingOrErrorOrNothing, SimpleAsyncState } from '@cyberpnk/component-library';

export function PerfectPunk({ punkId, punksDataImage }: { punkId: string, punksDataImage: SimpleAsyncState<string> }) {
  const dispatch = useAppDispatch();

  const unwrapLoading = useAppSelector(selectUnwrapLoading);
  const unwrapError = useAppSelector(selectUnwrapError);

  const disabledButtonUnwrap = unwrapLoading;
  const errorTextUnwrapping = unwrapError;

  const perfectPunkImage = `
  <svg width="640" height="640" version="1.1" viewBox="0 0 640 640" xmlns="http://www.w3.org/2000/svg">
  <defs><linearGradient id="bg" spreadMethod="repeat" x1="0" y1="0" x2="100%" y2="0"><stop offset="0%" stop-color="#ae95fb"/><stop stop-color="#6c8395" offset="70%"/></linearGradient></defs>
  <rect y="0" height="640" x="0" width="640" fill="url(#bg)"/>
   <style>text {font-family: monospace; fill: black; font-weight: bold;font-size:2em;}</style>
   ${punksDataImage.response}
   <rect y="590" height="40" fill="white" opacity="0.5" stroke="black" x="100" width="460"/>
   <text x="50%" text-anchor="middle" y="620">Perfect Punk #${punkId} V1+V2</text>
</svg>`;

  const imageBase64 = `data:image/svg+xml;base64,${encodeURI(window.btoa(perfectPunkImage))}`;

  return (
    <>
      <h2>You own Perfect Punk #{punkId}</h2>
      <LoadingOrErrorOrContent loading={punksDataImage.loading} error={punksDataImage.error} loadingText={"Loading Image..."}>
        <img alt={`Perfect Punk ${punkId}`} src={imageBase64} width="512" height="512" />
      </LoadingOrErrorOrContent>

      <div className={styles.claim}>
        <p><Button cta={true} disabled={disabledButtonUnwrap} onClick={() => {
          dispatch(unwrapAsync({ punkId }));
        }}>Unwrap Punks</Button></p>
        <LoadingOrErrorOrNothing loading={unwrapLoading} error={errorTextUnwrapping} loadingText={"Pending transaction..."} />
      </div>
    </>
  );
}
