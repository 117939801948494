import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  connectWalletReducer, hasWalletAsync,
  listenForNetworkChange, listenForWalletChange,
} from '@cyberpnk/component-library';
import { perfectPunksReducer } from '../features/PerfectPunks/perfectPunksSlice';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { loadImageAfterCheckEpic, recheckPunkEpic } from '../features/PerfectPunks/perfectPunksEpic';

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: {
    connectWallet: connectWalletReducer,
    perfectPunks: perfectPunksReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();
    return middleware.concat(epicMiddleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

store.dispatch(hasWalletAsync());
store.dispatch(listenForWalletChange());
store.dispatch(listenForNetworkChange());

export const rootEpic = combineEpics(
  recheckPunkEpic,
  loadImageAfterCheckEpic
);

// @ts-ignore
epicMiddleware.run(rootEpic);
