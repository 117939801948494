import React from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  wrapAsync,
  selectWrapError,
  selectWrapLoading,
  selectCheckResponse,
  approveV1Async,
  approveV2Async,
  selectApproveV1Loading,
  selectApproveV1Error,
  selectApproveV2Loading,
  selectApproveV2Error,
} from './perfectPunksSlice';
import styles from './UnwrappedPunks.module.css';
import {
  Button, LoadingOrErrorOrContent,
  LoadingOrErrorOrNothing, Network, selectNetwork, SimpleAsyncState
} from '@cyberpnk/component-library';
import addresses from "../../contract-addresses.json";

export function UnwrappedPunks({ punkId, punksDataImage }: { punkId: string, punksDataImage: SimpleAsyncState<string> }) {
  const dispatch = useAppDispatch();

  const wrapLoading = useAppSelector(selectWrapLoading);
  const wrapError = useAppSelector(selectWrapError);
  const checkResponse = useAppSelector(selectCheckResponse);
  const network: Network = useAppSelector(selectNetwork);
  const approveV1Loading = useAppSelector(selectApproveV1Loading);
  const approveV1Error = useAppSelector(selectApproveV1Error);
  const approveV2Loading = useAppSelector(selectApproveV2Loading);
  const approveV2Error = useAppSelector(selectApproveV2Error);

  const v1PunkImage = `
<svg width="640" height="640" version="1.1" viewBox="0 0 640 640" xmlns="http://www.w3.org/2000/svg">
  <rect y="0" height="640" x="0" width="640" fill="#ae95fb"/>
  ${punksDataImage.response}
</svg>`;

  const v2PunkImage = `
<svg width="640" height="640" version="1.1" viewBox="0 0 640 640" xmlns="http://www.w3.org/2000/svg">
  <rect y="0" height="640" x="0" width="640" fill="#6c8395"/>
  ${punksDataImage.response}
</svg>`;

  const v1ImageBase64 = `data:image/svg+xml;base64,${encodeURI(window.btoa(v1PunkImage))}`;
  const v2ImageBase64 = `data:image/svg+xml;base64,${encodeURI(window.btoa(v2PunkImage))}`;

  const showV1WrappingHelp = checkResponse?.v1IsOwnedByUser;
  const showV2WrappingHelp = checkResponse?.v2IsOwnedByUser;
  const showV1ApproveHelp = checkResponse?.v1WrappedIsOwnedByUser && !checkResponse?.v1WrappedIsApproved;
  const showV2ApproveHelp = checkResponse?.v2WrappedIsOwnedByUser && !checkResponse?.v2WrappedIsApproved;

  const v1WrapperContractApproveAddress = `https://etherscan.io/address/${addresses[network]?.PunksV1Wrapper}#writeContract`;
  const v2WrapperContractApproveAddress = `https://etherscan.io/address/${addresses[network]?.WrappedPunk}#writeContract`;
  const perfectPunksAddress = addresses[network].PerfectPunks;

  const disabledUnwrapButton = wrapLoading || showV1WrappingHelp || showV2WrappingHelp || showV1ApproveHelp || showV2ApproveHelp;
  const disabledApproveV1Button = approveV1Loading;
  const disabledApproveV2Button = approveV2Loading;

  return (
    <>
      <h2>Your Punks V1 #{punkId} and V2 #{punkId} can be combined into a Perfect Punk!</h2>
      <LoadingOrErrorOrContent loading={punksDataImage.loading} error={punksDataImage.error} loadingText={"Loading Image..."}>
        <div className={`${styles.punk} ${styles.v1Punk}`}>
          <img alt={`V1 ${punkId}`} className={`${styles.punkImage} ${styles.punkV1Image} ${showV1WrappingHelp || showV1ApproveHelp ? styles.needsAction : ""}`} src={v1ImageBase64} width="512" height="512" />
          {showV1WrappingHelp ? <div>
            Your V1 Punk needs to be wrapped before combining it into a Perfect Punk.
            <a rel="noreferrer" target="_blank" href="https://www.v1punks.io/profile">Wrap V1 Punks here</a>
          </div> : null}
          {showV1ApproveHelp ? <div>
            <p className={styles.text}>You need to approve the Perfect Punks contract to use your Wrapped V1 Punk.</p>
            <p>
              <Button cta={true} disabled={disabledApproveV1Button} onClick={() => {
                dispatch(approveV1Async({ punkId }));
              }}>Approve V1 #{punkId}</Button>
            </p>
            <LoadingOrErrorOrNothing loading={approveV1Loading} error={approveV1Error} loadingText={"Pending transaction..."} />
            <p className={styles.approveAllText}>If you know what you're doing and want to approve the Perfect Punks
              contract ({perfectPunksAddress}) for all your V1 Punks, do so at the{' '}
              <a href={v1WrapperContractApproveAddress}>V1 wrapper contract</a>.
            </p>
          </div> : null}
        </div>
        <div className={styles.punk}>
          <img alt={`V2 ${punkId}`} className={`${styles.punkImage} ${styles.punkV2Image} ${showV2WrappingHelp || showV2ApproveHelp ? styles.needsAction : ""}`} src={v2ImageBase64} width="512" height="512" />
          {showV2WrappingHelp ? <div>
            Your V2 Punk needs to be wrapped before combining it into a Perfect Punk.
            <a rel="noreferrer" target="_blank" href="https://www.wrappedpunks.com/">Wrap V2 Punks here</a>
          </div> : null}
          {showV2ApproveHelp ? <div>
            <p className={styles.text}>You need to approve the Perfect Punks contract to use your Wrapped V2 Punk.</p>
            <p>
              <Button cta={true} disabled={disabledApproveV2Button} onClick={() => {
                dispatch(approveV2Async({ punkId }));
              }}>Approve V2 #{punkId}</Button>
            </p>
            <LoadingOrErrorOrNothing loading={approveV2Loading} error={approveV2Error} loadingText={"Pending transaction..."} />
            <p className={styles.approveAllText}>If you know what you're doing and want to approve the Perfect Punks
              contract ({perfectPunksAddress}) for all your V2 Punks, do so at the{' '}
              <a href={v2WrapperContractApproveAddress}>V2 wrapper contract</a>.
            </p>
          </div> : null}
        </div>
      </LoadingOrErrorOrContent>

      <div className={styles.claim}>
        <div className={styles.tokenWrapper}>
          <div className={styles.unwrappedV1}>
          </div>
          <div className={styles.unwrappedV2}>
          </div>
        </div>
        <p><Button cta={true} disabled={disabledUnwrapButton} onClick={() => {
          dispatch(wrapAsync({ punkId }));
        }}>Create Perfect Punk</Button></p>
        <LoadingOrErrorOrNothing loading={wrapLoading} error={wrapError} loadingText={"Pending transaction..."} />
      </div>
    </>
  );
}
