import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { connectWalletAsync, newWalletConnected, SimpleAsyncState, walletDisconnected } from '@cyberpnk/component-library';
import { wrap, unwrap, check, CheckPunksResponse, CheckPunkRequest, getPunksDataImageWithLocalStorageCache, approveV1, approveV2 } from './perfectPunksApi';

export interface PerfectPunksState {
  wrap: SimpleAsyncState<void>;
  unwrap: SimpleAsyncState<void>;
  check: SimpleAsyncState<CheckPunksResponse, CheckPunkRequest>;
  punksDataImage: SimpleAsyncState<string>;
  approveV1: SimpleAsyncState<void>;
  approveV2: SimpleAsyncState<void>;
}

const initialState: PerfectPunksState = {
  wrap: {
    loading: false,
  },
  unwrap: {
    loading: false,
  },
  check: {
    loading: false
  },
  punksDataImage: {
    loading: false,
  },
  approveV1: {
    loading: false,
  },
  approveV2: {
    loading: false,
  },
};

export const checkAsync = createAsyncThunk(
  'perfectPunks/check',
  async ({ punkId, expectedOwner }: CheckPunkRequest, thunkAPI) => {
    return await check({ punkId, expectedOwner });
  }
);

export const getPunksDataImageAsync = createAsyncThunk(
  'perfectPunks/getPunksDataImage',
  async ({ punkId }: { punkId: string }, thunkAPI) => {
    return await getPunksDataImageWithLocalStorageCache({ punkId });
  }
);

export const wrapAsync = createAsyncThunk(
  'perfectPunks/wrap',
  async ({ punkId }: { punkId: string }, thunkAPI) => {
    await wrap(punkId);
  }
);

export const unwrapAsync = createAsyncThunk(
  'perfectPunks/unwrap',
  async ({ punkId }: { punkId: string }, thunkAPI) => {
    await unwrap(punkId);
  }
);

export const approveV1Async = createAsyncThunk(
  'perfectPunks/approveV1',
  async ({ punkId }: { punkId: string }, thunkAPI) => {
    await approveV1(punkId);
  }
);

export const approveV2Async = createAsyncThunk(
  'perfectPunks/approveV2',
  async ({ punkId }: { punkId: string }, thunkAPI) => {
    await approveV2(punkId);
  }
);

export const perfectPunksSlice = createSlice({
  name: 'perfectPunks',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(wrapAsync.pending, (state) => {
        state.wrap.loading = true;
        state.wrap.error = undefined;
      })
      .addCase(wrapAsync.fulfilled, (state, action) => {
        state.wrap.loading = false;
      })
      .addCase(wrapAsync.rejected, (state, action) => {
        state.wrap.loading = false;
        state.wrap.error = "There was an error with the transaction"
      })
      .addCase(unwrapAsync.pending, (state) => {
        state.unwrap.loading = true;
        state.unwrap.error = undefined;
      })
      .addCase(unwrapAsync.fulfilled, (state, action) => {
        state.unwrap.loading = false;
      })
      .addCase(unwrapAsync.rejected, (state, action) => {
        state.unwrap.loading = false;
        state.unwrap.error = "There was an error with the transaction"
      })
      .addCase(checkAsync.pending, (state, action) => {
        state.check.loading = true;
        state.check.request = action.meta.arg;
        state.check.error = undefined;
        state.approveV1 = initialState.approveV1;
        state.approveV2 = initialState.approveV2;
        state.wrap = initialState.wrap;
        state.unwrap = initialState.unwrap;
        state.punksDataImage = initialState.punksDataImage;
      })
      .addCase(checkAsync.fulfilled, (state, action) => {
        state.check.loading = false;
        state.check.response = action.payload;
      })
      .addCase(checkAsync.rejected, (state, action) => {
        state.check.loading = false;
        state.check.error = "There was an error with the transaction"
      })
      .addCase(getPunksDataImageAsync.pending, (state, action) => {
        state.punksDataImage.loading = true;
        state.punksDataImage.error = undefined;
      })
      .addCase(getPunksDataImageAsync.fulfilled, (state, action) => {
        state.punksDataImage.loading = false;
        state.punksDataImage.response = action.payload;
      })
      .addCase(getPunksDataImageAsync.rejected, (state, action) => {
        state.punksDataImage.loading = false;
        state.punksDataImage.error = "Error loading image"
      })
      .addCase(connectWalletAsync.pending, (state) => {
        state.check = initialState.check;
      })
      .addCase(newWalletConnected, (state) => {
        state.check = initialState.check;
      })
      .addCase(walletDisconnected, (state) => {
        state.check = initialState.check;
      })
      .addCase(approveV1Async.pending, (state) => {
        state.approveV1.loading = true;
        state.approveV1.error = undefined;
      })
      .addCase(approveV1Async.fulfilled, (state, action) => {
        state.approveV1.loading = false;
      })
      .addCase(approveV1Async.rejected, (state, action) => {
        state.approveV1.loading = false;
        state.approveV1.error = "There was an error approving"
      })
      .addCase(approveV2Async.pending, (state) => {
        state.approveV2.loading = true;
        state.approveV2.error = undefined;
      })
      .addCase(approveV2Async.fulfilled, (state, action) => {
        state.approveV2.loading = false;
      })
      .addCase(approveV2Async.rejected, (state, action) => {
        state.approveV2.loading = false;
        state.approveV2.error = "There was an error approving"
      })

  },
});


export const selectWrapLoading = (state: any) => state.perfectPunks.wrap.loading;
export const selectWrapError = (state: any) => state.perfectPunks.wrap.error;
export const selectUnwrapLoading = (state: any) => state.perfectPunks.unwrap.loading;
export const selectUnwrapError = (state: any) => state.perfectPunks.unwrap.error;
export const selectPendingTx = (state: any) => state.perfectPunks.wrap.loading || state.perfectPunks.unwrap.loading;
export const selectCheckLoading = (state: any) => state.perfectPunks.check.loading;
export const selectCheckError = (state: any) => state.perfectPunks.check.error;
export const selectCheckResponse = (state: any) => state.perfectPunks.check.response;
export const selectCheckRequest = (state: any) => state.perfectPunks.check.request;
export const selectPunksDataImage = (state: any) => state.perfectPunks.punksDataImage;
export const selectApproveV1Loading = (state: any) => state.perfectPunks.approveV1.loading;
export const selectApproveV1Error = (state: any) => state.perfectPunks.approveV1.error;
export const selectApproveV2Loading = (state: any) => state.perfectPunks.approveV2.loading;
export const selectApproveV2Error = (state: any) => state.perfectPunks.approveV2.error;


export const perfectPunksReducer = perfectPunksSlice.reducer;

export default perfectPunksSlice.reducer;
