import { ContractPerNetwork, getNetworkFromWallet } from "@cyberpnk/component-library";
import addresses from "../contract-addresses.json";
import { abi as PerfectPunksABI } from "../PerfectPunks.json";
import { abi as ERC721ABI } from "../ERC721.json";
import { abi as CryptoPunksMarketV1ABI } from "../CryptoPunksMarketV1.json";
import { abi as CryptoPunksMarketV2ABI } from "../CryptoPunksMarketV1.json";
import { abi as CryptoPunksDataABI } from "../CryptoPunksData.json";

import Web3 from "web3";
import { AbiItem } from "web3-utils";

declare global {
  interface Window { ethereum: any; }
}

export const getContractAddress = async (contractName: string): Promise<string> => {
  const web3 = new Web3(window.ethereum);
  const network = await getNetworkFromWallet(web3);
  return addresses[network].PerfectPunks;
};

let perfectPunksContract: ContractPerNetwork = {};
export const getPerfectPunksContract = async () => {
  const web3 = new Web3(window.ethereum);
  const network = await getNetworkFromWallet(web3);
  const perfectPunksAddress = addresses[network].PerfectPunks;
  if (!perfectPunksContract[network]) {
    perfectPunksContract[network] = await new web3.eth.Contract(PerfectPunksABI as AbiItem[], perfectPunksAddress);
  }
  return perfectPunksContract[network];
}

let v1WrappedContract: ContractPerNetwork = {};
export const getV1WrappedContract = async () => {
  const web3 = new Web3(window.ethereum);
  const network = await getNetworkFromWallet(web3);
  const v1WrappedAddress = addresses[network].PunksV1Wrapper;
  if (!v1WrappedContract[network]) {
    v1WrappedContract[network] = await new web3.eth.Contract(ERC721ABI as AbiItem[], v1WrappedAddress);
  }
  return v1WrappedContract[network];
}

let v2WrappedContract: ContractPerNetwork = {};
export const getV2WrappedContract = async () => {
  const web3 = new Web3(window.ethereum);
  const network = await getNetworkFromWallet(web3);
  const v2WrappedAddress = addresses[network].WrappedPunk;
  if (!v2WrappedContract[network]) {
    v2WrappedContract[network] = await new web3.eth.Contract(ERC721ABI as AbiItem[], v2WrappedAddress);
  }
  return v2WrappedContract[network];
}

let v1Contract: ContractPerNetwork = {};
export const getV1Contract = async () => {
  const web3 = new Web3(window.ethereum);
  const network = await getNetworkFromWallet(web3);
  const v1Address = addresses[network].CryptoPunksMarketV1;
  if (!v1Contract[network]) {
    v1Contract[network] = await new web3.eth.Contract(CryptoPunksMarketV1ABI as AbiItem[], v1Address);
  }
  return v1Contract[network];
}

let v2Contract: ContractPerNetwork = {};
export const getV2Contract = async () => {
  const web3 = new Web3(window.ethereum);
  const network = await getNetworkFromWallet(web3);
  const v2Address = addresses[network].CryptoPunksMarketV2;
  if (!v2Contract[network]) {
    v2Contract[network] = await new web3.eth.Contract(CryptoPunksMarketV2ABI as AbiItem[], v2Address);
  }
  return v2Contract[network];
}

let cryptoPunksDataContract: ContractPerNetwork = {};
export const getCryptoPunksDataContract = async () => {
  const web3 = new Web3(window.ethereum);
  const network = await getNetworkFromWallet(web3);
  const cryptoPunksDataAddress = addresses[network].CryptoPunksData;
  if (!cryptoPunksDataContract[network]) {
    cryptoPunksDataContract[network] = await new web3.eth.Contract(CryptoPunksDataABI as AbiItem[], cryptoPunksDataAddress);
  }
  return cryptoPunksDataContract[network];
}


