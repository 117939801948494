import React from 'react';
import styles from './App.module.css';
import { useAppSelector, useAppDispatch } from './app/hooks';
import {
  Network, Footer,
  ConnectWallet, selectConnected,
  selectNetwork,
  TopBar,
  ConnectWalletStatus
} from '@cyberpnk/component-library';
import { version } from '../package.json';
import { getPerfectPunksContract } from './app/common';
import { PerfectPunks } from './features/PerfectPunks/PerfectPunks';
import addresses from "./contract-addresses.json";

function App() {
  const connected = useAppSelector(selectConnected);
  const network: Network = useAppSelector(selectNetwork);

  const isKnownNetwork = network === Network.Rinkeby || network === Network.Mainnet;

  const properlyConnected = connected && isKnownNetwork;
  const showConnectStep = !properlyConnected;

  const showMain = properlyConnected;

  return (
    <>
      <div className={styles.App}>
        <TopBar>
          <ConnectWalletStatus getContract={getPerfectPunksContract} useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
        </TopBar>
        <main>
          <h1>Perfect Punks</h1>
          <h2>Combine the same v1 and v2 punks that you own to create a Perfect Punk</h2>

          {showConnectStep ?
            <>
              <p>Connect the wallet that holds both V1 and V2 punks you want to combine</p>
              <ConnectWallet getContract={getPerfectPunksContract} useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
            </>
            : null}

          {showMain ? <div className={styles.main}><PerfectPunks /></div> : null}
        </main>
      </div>
      <div className={styles.footer}>
        <Footer version={version} contractAddress={addresses[network]?.PerfectPunks} openSeaCollection={"perfect-cryptopunks"} network={network} />
      </div>
    </>
  );
}

export default App;
